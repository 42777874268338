<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card width="350">
            <v-card-title class="justify-center align-center nuvolos-header">
                <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
            </v-card-title>
            <h2 class="secondary--text text-center text-h6 mt-5">Preparing your {{ getUserName ? 'account' : 'workspace' }}</h2>
            <user-name-form v-if="getUserName" @createAccount="signUpFlow"></user-name-form>
            <template v-else>
                <v-card-text>
                    This might take a few seconds.
                    <br />
                    Please bear with us until we're done.
                </v-card-text>
                <v-card-text class="text-center pb-16">
                    <dashboard-error v-if="error" :msg="errorMsg" />
                    <template v-else>
                        <p>{{ signupFlowProgressText }}</p>
                        <v-progress-linear stream buffer-value="0" v-model="progress" color="primary" rounded height="6"></v-progress-linear>
                    </template>
                    <v-btn v-if="nuvolosError && error.response.data.code === 'user_already_exists'" :to="{ name: 'login' }" class="mt-4">go to login</v-btn>
                </v-card-text>
            </template>
        </v-card>
    </div>
</template>

<script>
const DashboardError = () => import('@/components/DashboardError.vue')
const UserNameForm = () => import('@/modules/invitations/components/UserNameForm.vue')

export default {
    name: 'InvitationProvider',
    mixins: [],
    components: { DashboardError, UserNameForm },
    data() {
        return {
            signupFlowProgressText: '',
            error: null,
            errorMsg: '',
            invitationId: '',
            progress: 0,
            overviewURI: '',
            dashboardOid: '',
            getUserName: false
        }
    },
    created() {},
    methods: {
        async beforeAccountDetailsFlow() {
            if (!this.$auth.user.family_name || !this.$auth.user.given_name) {
                await new Promise(resolve => setTimeout(resolve, 800))
                this.getUserName = true
            } else {
                this.signUpFlow({ givenName: this.$auth.user.given_name, familyName: this.$auth.user.family_name })
            }
        },
        async signUpFlow(info) {
            this.getUserName = false
            this.signupFlowProgressText = 'Creating user account.'
            const step3 = await this.createUser(info)
            if (this.$isError(step3)) return
            this.progress = 50

            this.signupFlowProgressText = 'Finalizing configuration.'
            await this.acceptInvitation()
            this.progress = 100
        },

        async createUser(info) {
            const provider = this.$route.params.provider === 'google' ? 'google-oauth2' : 'satosa'
            try {
                const { data } = await this.$axios.post(`/users/register_with_invitation/${provider}/${this.$route.params.invid}`, {
                    email: this.$auth.user.email,
                    given_name: info.givenName,
                    family_name: info.familyName
                })
                this.$store.dispatch('getUserInfo')
                return data
            } catch (error) {
                if (error?.response?.data?.code === 'connection_already_registered') {
                    this.errorMsg = 'A Nuvolos account with this email address already exists, please log in instead.'
                }
                if (error?.response?.data?.code === 'max_registrations_reached') {
                    this.errorMsg =
                        'Already registered! No further account registration is permitted with this invitation. Please log in with an existing account to accept the invitation.'
                }
                this.error = error
                return error
            }
        },
        async acceptInvitation() {
            await new Promise(resolve => setTimeout(resolve, 800))
            this.$router.push({
                name: 'invitation-accept',
                params: { invid: this.$route.params.invid }
            })
        }
    },
    computed: {
        nuvolosError() {
            return this.error?.response?.data?.code
        }
    },
    watch: {
        '$auth.isAuthenticated': {
            handler(authenticated) {
                if (authenticated) {
                    this.beforeAccountDetailsFlow()
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
